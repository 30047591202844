import { render, staticRenderFns } from "./TopicsFilterTable.vue?vue&type=template&id=415d9d70&"
import script from "./TopicsFilterTable.vue?vue&type=script&lang=js&"
export * from "./TopicsFilterTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../locales/common.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fbig-dig-data%2Fshaft%2Fdesign%2Fshaft-ui%2Fsrc%2Fcomponents%2FTopicsFilterTable.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./TopicsFilterTable.vue?vue&type=custom&index=1&blockType=i18n"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VDataTable,VTextField,VTooltip})
