var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("pages.works"))+" "),_c('v-spacer'),_c('v-row',[(_vm.showCatalogYearFilter)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.catalogYears,"label":_vm.$t('catalog_year'),"value":_vm.selectedYear,"solo":"","clearable":""},on:{"change":_vm.yearChange}})],1):_vm._e(),_c('v-col',{attrs:{"cols":_vm.showCatalogYearFilter ? 6 : 12}},[_c('v-text-field',{attrs:{"append-icon":"fa fa-search","label":_vm.$t('search'),"clearable":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.rows,"options":_vm.options,"server-items-length":_vm.totalRows,"loading":_vm.loading,"items-per-page":_vm.pageSize,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"footer-props":{
      'items-per-page-text': _vm.$t('table.rows_per_page'),
      'items-per-page-options': _vm.$numRowsOpts,
    },"item-key":"pk","show-expand":"","dense":""},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'work detail', params: { workId: item.pk } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.authors",fn:function(ref){
    var item = ref.item;
return _vm._l((item.authors),function(author){return _c('span',{key:author.pk,staticClass:"item"},[_c('ExplicitTopicLink',{attrs:{"topic-type":"author","topic-id":author.pk}},[_vm._v(" "+_vm._s(author.name)+" ")]),_c('v-icon',{staticClass:"icon mx-1",attrs:{"x-small":""}},[_vm._v("fas fa-grip-lines-vertical")])],1)})}},{key:"item.publishers",fn:function(ref){
    var item = ref.item;
return _vm._l((item.publishers),function(publisher){return _c('span',{key:publisher.pk,staticClass:"item"},[_c('ExplicitTopicLink',{attrs:{"topic-type":"publisher","topic-id":publisher.pk}},[_vm._v(" "+_vm._s(publisher.name)+" ")]),_c('v-icon',{staticClass:"icon mx-1",attrs:{"x-small":""}},[_vm._v("fas fa-grip-lines-vertical")])],1)})}},{key:"item.subject_categories",fn:function(ref){
    var item = ref.item;
return _vm._l((item.subject_categories),function(subject){return _c('span',{key:subject.pk,staticClass:"item"},[_c('ExplicitTopicLink',{attrs:{"topic-type":"psh","topic-id":subject.pk}},[_vm._v(" "+_vm._s(subject.name)+" ")]),_c('v-icon',{staticClass:"icon mx-1",attrs:{"x-small":""}},[_vm._v("fas fa-grip-lines-vertical")])],1)})}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-simple-table',[_c('tbody',[_c('tr',[_c('th',[_vm._v("ISBN")]),_c('td',[_vm._v(_vm._s(item.isbn.join(", ")))])]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("pages.work_types")))]),_c('td',[(item.category)?_c('ExplicitTopicLink',{attrs:{"topic-type":"work-type","topic-id":item.category.pk}},[_vm._v(" "+_vm._s(item.category.name)+" ")]):_vm._e()],1)]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("columns.abstract")))]),_c('td',[_vm._v(_vm._s(item.abstract))])])])])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }