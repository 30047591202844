import { render, staticRenderFns } from "./SubjectTree.vue?vue&type=template&id=3a155d30&scoped=true&"
import script from "./SubjectTree.vue?vue&type=script&lang=js&"
export * from "./SubjectTree.vue?vue&type=script&lang=js&"
import style0 from "./SubjectTree.vue?vue&type=style&index=0&id=3a155d30&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a155d30",
  null
  
)

/* custom blocks */
import block0 from "../locales/common.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fbig-dig-data%2Fshaft%2Fdesign%2Fshaft-ui%2Fsrc%2Fcomponents%2FSubjectTree.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./SubjectTree.vue?vue&type=custom&index=1&blockType=i18n"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCard,VProgressLinear,VTextField,VTooltip,VTreeview})
