import { render, staticRenderFns } from "./WorkFilterDialog.vue?vue&type=template&id=0fc0b37e&scoped=true&"
import script from "./WorkFilterDialog.vue?vue&type=script&lang=js&"
export * from "./WorkFilterDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fc0b37e",
  null
  
)

/* custom blocks */
import block0 from "@/locales/dialog.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fbig-dig-data%2Fshaft%2Fdesign%2Fshaft-ui%2Fsrc%2Fcomponents%2FWorkFilterDialog.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./WorkFilterDialog.vue?vue&type=custom&index=1&blockType=i18n&lang=yaml"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VContainer,VRow,VSpacer})
