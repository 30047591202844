<i18n src="../locales/common.yaml"></i18n>
<i18n>
en:
  filters: Filters
  settings_header: Settings profiles
  load_profile: Select profile
  save_as: Save as...
  create_success: New settings profile created successfully
  update_success: Settings profile updated successfully
  profile_name: Settings profile name
  name_empty: Profile name is required
  save: Save
  export_csv: Export .csv
  cancel: Cancel
  publishers_selected: Publishers selected
  langs_selected: Languages selected
  subjects_selected: Subjects selected
  create_profile: Create new profile
  save_settings: Save current setting under the same name
cs:
  filters: Filtry
  settings_header: Profily nastavení
  load_profile: Vyběr profilu
  save_as: Uložit jako...
  create_success: Nový profil nastavení byl úspěšně vytvořený
  update_success: Profil nastavení byl úspěšně uložen
  profile_name: Jméno profilu nastavení
  name_empty: Je vyžadováno jméno profilu nastavení
  save: Uložit
  export_csv: Export .csv
  cancel: Cancel
  publishers_selected: Vybraní vydavatelé
  langs_selected: Vybrané jazyky
  subjects_selected: Vybraná témata
  create_profile: Vytvořit nový profil
  save_settings: Uložit současné nastavení pod stejným jménem
</i18n>

<template>
  <v-container fluid>
    <v-row justify="space-between">
      <v-col cols="auto" class="py-0">
        <v-card elevation="0">
          <v-card-text class="pb-0 d-flex">
            <span class="mt-2 mr-3 black-button text--primary"
              >{{ $t("settings_header") }}:</span
            >
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                  icon
                  class=""
                  top
                  color="blue lightgreen-2"
                  :disabled="saveDisabled"
                  @click="updateSelectedProfile()"
                  v-on="on"
                >
                  <v-icon>fas fa-save</v-icon>
                </v-btn>
              </template>
              {{ $t("save_settings") }}
            </v-tooltip>
            <v-select
              :items="profileNames"
              class="mr-3"
              dense
              :label="$t('load_profile')"
              v-model="selectedProfile"
              @change="selectedProfileChange"
            >
            </v-select>
            <v-btn
              color="info"
              outlined
              @click="createDialog = !createDialog"
              small
            >
              {{ $t("save_as") }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="auto" class="py-0">
        <v-btn color="success" class="mt-3" :href="exportHref" target="_blank">
          {{ $t("export_csv") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col :cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <v-row no-gutters>
                  <v-col cols="3">
                    {{ $t("filters") }}
                  </v-col>
                  <v-col cols="3">
                    <v-fade-transition leave-absolute>
                      <span v-if="!open">
                        {{ $t("publishers_selected") }}:
                        {{ filterCounts.publisher }}
                      </span>
                    </v-fade-transition>
                  </v-col>
                  <v-col cols="3">
                    <v-fade-transition leave-absolute>
                      <span v-if="!open">
                        {{ $t("langs_selected") }}: {{ filterCounts.language }}
                      </span>
                    </v-fade-transition>
                  </v-col>
                  <v-col cols="3">
                    <v-fade-transition leave-absolute>
                      <span v-if="!open">
                        {{ $t("subjects_selected") }}: {{ filterCounts.psh }}
                      </span>
                    </v-fade-transition>
                  </v-col>
                </v-row>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <v-row>
                <v-col :cols="4">
                  <TopicsFilterTable
                    urlArg="publisher"
                    :header="$t('columns.publisher')"
                    :candidate_count_filters="pubsFilters"
                    @filters-change="pubsFiltersChange"
                  />
                </v-col>
                <v-col :cols="3">
                  <TopicsFilterTable
                    urlArg="language"
                    :header="$t('pages.languages')"
                    :candidate_count_filters="langFilters"
                    @filters-change="langFiltersChange"
                  />
                </v-col>
                <v-col :cols="5"
                  ><SubjectTree
                    :height="'392px'"
                    :candidate_count_filters="subjectsFilters"
                    @filters-change="subjectsFiltersChange"
                    score_type="candidates_count"
                /></v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row>
      <v-col :col="12">
        <CandidatesTable
          :filters="filters"
          :ordering="ordering"
          :scoreYearIdx="scoreYearIdx"
          :scoreWeights="scoreWeights"
          :displayFilters="displayFilters"
          :formats="formats"
          @weights-change="weightsChange"
          @exportUrl-change="(val) => (exportHref = val)"
          @displayFilters-change="displayFiltersChange"
          @ordering-change="orderingChange"
          @formats-change="formatsChange"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="createDialog" max-width="500">
      <v-card>
        <v-card-title>
          {{ $t("create_profile") }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('profile_name')"
            required
            v-model="newProfileName"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="createDialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="primary" text @click="createSettingsProfile">
            {{ $t("save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import CandidatesTable from "@/components/CandidatesTable";
import TopicsFilterTable from "@/components/TopicsFilterTable";
import SubjectTree from "@/components/SubjectTree";
import { mapActions } from "vuex";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";

export default {
  name: "CandidatesTablePage",
  components: { CandidatesTable, TopicsFilterTable, SubjectTree },

  data() {
    return {
      filters: [],
      pubsFilters: [],
      langFilters: [],
      subjectsFilters: [],
      settingsProfiles: [],
      defaultSettingsProfile: null,
      namedSettingsProfile: null,
      scoreYearIdx: 5,
      scoreWeights: { authors: 1, publisher: 1, languages: 1, subjects: 1 },
      selectedProfile: null,
      createDialog: false,
      newProfileName: "",
      exportHref: null,
      displayFilters: {
        showUnreviewed: true,
        showLiked: true,
        showDisliked: false,
      },
      ordering: { sortBy: ["score"], sortDesc: [true] },
      saveDisabled: true,
      filterCounts: { publisher: 0, language: 0, psh: 0 },
      formats: [],
    };
  },

  computed: {
    profileNames() {
      return this.settingsProfiles.map((el) => {
        if (el.name != "default") return el.name;
      });
    },
  },

  watch: {
    defaultSettingsProfile: {
      handler() {
        this.checkSaveDisabled();
        this.debounceProfileUpdates();
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions({
      showSnackbar: "showSnackbar",
    }),
    pubsFiltersChange(newFilters) {
      this.filtersChange(newFilters);
      this.langFilters = cloneDeep(this.filters);
      this.subjectsFilters = cloneDeep(this.filters);
    },
    langFiltersChange(newFilters) {
      this.filtersChange(newFilters);
      this.pubsFilters = cloneDeep(this.filters);
      this.subjectsFilters = cloneDeep(this.filters);
    },
    subjectsFiltersChange(newFilters) {
      this.filtersChange(newFilters);
      this.pubsFilters = cloneDeep(this.filters);
      this.langFilters = cloneDeep(this.filters);
    },
    filtersChange(newFilters) {
      const old = this.filters.length ? cloneDeep(this.filters) : [];
      const names = old.map((el) => el.name);
      const filter_obj = cloneDeep(newFilters[0]);
      const idx = names.indexOf(filter_obj.name);
      if (idx > -1) {
        old[idx].id = filter_obj.id;
      } else {
        old.push(filter_obj);
      }
      this.filters = old.filter((el) => el.id.length);
      this.defaultSettingsProfile.settings_obj.filters = this.filters;
      this.setFilterCounts();
    },
    weightsChange(newVals) {
      this.defaultSettingsProfile.settings_obj.weights = newVals.weights;
      this.defaultSettingsProfile.settings_obj.scoreYearIdx =
        newVals.scoreYearIdx;
    },
    displayFiltersChange(newVals) {
      this.defaultSettingsProfile.settings_obj.displayFilters = newVals;
    },
    orderingChange(newVals) {
      this.defaultSettingsProfile.settings_obj.ordering = newVals;
    },
    formatsChange(newVals) {
      this.defaultSettingsProfile.settings_obj.formats = newVals;
    },
    async selectedProfileChange() {
      if (!this.selectedProfile) return null;
      let idx = this.profileNames.indexOf(this.selectedProfile);
      await this.loadSettingsProfile(this.settingsProfiles[idx].pk);
      this.updateSettings();
    },
    updateSelectedProfile() {
      let idx = this.profileNames.indexOf(this.selectedProfile);
      this.updateSettingsProfile(this.settingsProfiles[idx]);
    },

    async loadAllProfiles(applyDefault) {
      try {
        const resp = await axios.get("/api/candidates_settings/");
        resp.data.forEach((el) => {
          if (el.name == "default" && el.internal) {
            this.defaultSettingsProfile = el;
            if (applyDefault) this.updateSettings();
          } else this.settingsProfiles.push(el);
        });
      } catch (error) {
        this.showSnackbar({
          content: "Error fetching candidates settings profiles: " + error,
          color: "error",
        });
      }
    },
    async loadSettingsProfile(profileId) {
      const urlArg = profileId || "default_profile";
      try {
        const resp = await axios.get(`/api/candidates_settings/${urlArg}/`);
        if (profileId) this.namedSettingsProfile = resp.data;
        this.defaultSettingsProfile.settings_obj = resp.data.settings_obj;
      } catch (error) {
        this.showSnackbar({
          content: "Error loading candidates settings profile: " + error,
          color: "error",
        });
      }
    },
    async updateSettingsProfile(profile) {
      let profileObj = profile || this.defaultSettingsProfile;
      try {
        const resp = await axios.put(
          `/api/candidates_settings/${profileObj.pk}/`,
          {
            name: profile ? this.selectedProfile : profileObj.name,
            settings_obj: this.defaultSettingsProfile.settings_obj,
          }
        );
        if (profile && resp.status == 200) {
          this.selectedProfile = profile.name;
          // update the stored profile
          for (let item of this.settingsProfiles) {
            if (item.pk === resp.data.pk) {
              this.$set(item, "settings_obj", resp.data.settings_obj);
              break;
            }
          }
          this.checkSaveDisabled();
          this.showSnackbar({
            content: this.$i18n.t("update_success"),
            color: "success",
          });
        }
      } catch (error) {
        this.showSnackbar({
          content: "Error updating candidates settings profile: " + error,
          color: "error",
        });
      }
    },
    async createSettingsProfile() {
      if (!this.newProfileName) {
        this.showSnackbar({
          content: this.$i18n.t("name_empty"),
          color: "error",
        });
        return null;
      }
      let settingsObj = this.defaultSettingsProfile.settings_obj;
      try {
        const resp = await axios.post(`/api/candidates_settings/`, {
          name: this.newProfileName,
          settings_obj: settingsObj,
        });
        if (resp.status == 201) {
          this.showSnackbar({
            content: this.$i18n.t("create_success"),
            color: "success",
          });
          this.loadAllProfiles(false);
          this.selectedProfile = this.newProfileName;
        }
      } catch (error) {
        this.showSnackbar({
          content: "Error updating candidates settings profile: " + error,
          color: "error",
        });
      } finally {
        this.createDialog = false;
      }
    },

    updateSettings(profile) {
      let profileObj = profile || this.defaultSettingsProfile;
      if (profileObj.settings_obj) {
        this.scoreWeights = profileObj.settings_obj.weights;
        this.scoreYearIdx = profileObj.settings_obj.scoreYearIdx;
        this.filters = profileObj.settings_obj.filters;
        this.subjectsFilters = this.filters;
        this.pubsFilters = this.filters;
        this.langFilters = this.filters;
        this.displayFilters = profileObj.settings_obj.displayFilters;
        this.ordering = profileObj.settings_obj.ordering;
        this.formats = profileObj.settings_obj.formats;
      }
    },

    debounceProfileUpdates: debounce(function () {
      this.updateSettingsProfile();
    }, 500),

    setFilterCounts() {
      for (let f of this.filters) {
        this.filterCounts[f.name] = f.id.length;
      }
    },
    checkSaveDisabled() {
      if (this.selectedProfile) {
        let selectedProfileObjAsSaved = this.settingsProfiles.find(
          (item) => item.name === this.selectedProfile
        );
        if (
          !isEqual(
            this.defaultSettingsProfile.settings_obj,
            selectedProfileObjAsSaved.settings_obj
          )
        ) {
          this.saveDisabled = false;
        } else {
          this.saveDisabled = true;
        }
      }
    },
  },

  created() {
    this.loadAllProfiles(true);
  },
};
</script>
