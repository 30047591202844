import { render, staticRenderFns } from "./ExplicitTopicDetailPage.vue?vue&type=template&id=1fe716cc&"
import script from "./ExplicitTopicDetailPage.vue?vue&type=script&lang=js&"
export * from "./ExplicitTopicDetailPage.vue?vue&type=script&lang=js&"
import style0 from "./ExplicitTopicDetailPage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/locales/topic-types.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fbig-dig-data%2Fshaft%2Fdesign%2Fshaft-ui%2Fsrc%2Fpages%2FExplicitTopicDetailPage.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./ExplicitTopicDetailPage.vue?vue&type=custom&index=1&blockType=i18n"
if (typeof block1 === 'function') block1(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAutocomplete,VCol,VContainer,VIcon,VListItemContent,VListItemSubtitle,VListItemTitle,VRow})
