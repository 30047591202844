var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{attrs:{"clearable":"","placeholder":_vm.$t('search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-card',{attrs:{"loading":_vm.loading}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"deep-purple","height":"10","indeterminate":""}})],1),_c('v-treeview',{staticClass:"overflow",style:(("max-height: " + _vm.height)),attrs:{"selection-type":_vm.selectionType,"items":_vm.tree,"search":_vm.search,"selectable":"","dense":""},on:{"input":_vm.selectedChange},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.withLinks)?_c('ExplicitTopicLink',{attrs:{"topic-type":"psh","topic-id":item.id}},[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.name))])]):_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.name))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.score_type == 'score')?_c('span',[(item.acc_score != 0)?_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.formatInteger(item.score))+" / "+_vm._s(_vm.formatInteger(item.acc_score))+" ")]):_vm._e()]):(_vm.score_type == 'candidates_count')?_c('span',[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.formatInteger(item.acc_score))+" ")])]):_c('span',[_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('span',{class:((item.absolute_growth > 0 ? 'green' : 'red') + "--text text-caption")},[_vm._v(" "+_vm._s(_vm.formatRelGrowth(item.relative_growth))+" ")]),_vm._v(" / "),_c('span',{class:((item.acc_absolute_growth > 0 ? 'green' : 'red') + "--text text-caption")},[_vm._v(" "+_vm._s(_vm.formatRelGrowth(item.acc_relative_growth))+" ")])])])]}}],null,true)},[_c('span',{staticClass:"d-flex flex-column"},[(_vm.score_type == 'growth')?_c('span',[_vm._v(" "+_vm._s(_vm.formatRelGrowth(item.relative_growth))+" ("+_vm._s(item.score_yr_b4)+" -> "+_vm._s(item.score_past_yr)+") / "+_vm._s(_vm.formatRelGrowth(item.acc_relative_growth))+" ("+_vm._s(item.acc_score_yr_b4)+" -> "+_vm._s(item.acc_score_past_yr)+") ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.tooltipMap[_vm.score_type])+" ")])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }