const CHART_COLORS = {
  pythia: [
    "#19d4ae",
    "#5ab1ef",
    "#fa6e86",
    "#ffb980",
    "#0067a6",
    "#c4b4e4",
    "#d87a80",
    "#9cbbff",
    "#d9d0c7",
    "#87a997",
    "#d49ea2",
    "#5b4947",
    "#7ba3a8",
  ],
  libushe: [
    "#0067a6",
    "#ffb980",
    "#fa6e86",
    "#19d4ae",
    "#5ab1ef",
    "#c4b4e4",
    "#d87a80",
    "#9cbbff",
    "#d9d0c7",
    "#87a997",
    "#d49ea2",
    "#5b4947",
    "#7ba3a8",
  ],
};

export { CHART_COLORS };
