<i18n src="../locales/common.yaml"></i18n>
<i18n>
en:
  filters: Filters
cs:
  filters: Filtry
</i18n>
<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $t("filters") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col :cols="4">
                  <TopicsFilterTable
                    urlArg="publisher"
                    :header="$t('columns.publisher')"
                    score_type="growth"
                    @filters-change="pubsFiltersChange"
                  />
                </v-col>
                <v-col :cols="3">
                  <TopicsFilterTable
                    urlArg="language"
                    :header="$t('pages.languages')"
                    score_type="growth"
                    @filters-change="langFiltersChange"
                  />
                </v-col>
                <v-col :cols="5"
                  ><SubjectTree
                    :height="'392px'"
                    score_type="growth"
                    @filters-change="subjectsFiltersChange"
                /></v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <WorksGrowthTable :filters="filters" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import WorksGrowthTable from "./WorksGrowthTable";
import TopicsFilterTable from "./TopicsFilterTable";
import SubjectTree from "@/components/SubjectTree";
export default {
  name: "WorksGrowthOverview",
  components: { WorksGrowthTable, TopicsFilterTable, SubjectTree },

  data() {
    return {
      filters: [],
      pubsFilters: [],
      langFilters: [],
      subjectsFilters: [],
    };
  },

  methods: {
    pubsFiltersChange(newFilters) {
      this.filtersChange(newFilters);
      this.langFilters = cloneDeep(this.filters);
      this.subjectsFilters = cloneDeep(this.filters);
    },
    langFiltersChange(newFilters) {
      this.filtersChange(newFilters);
      this.pubsFilters = cloneDeep(this.filters);
      this.subjectsFilters = cloneDeep(this.filters);
    },
    subjectsFiltersChange(newFilters) {
      this.filtersChange(newFilters);
      this.pubsFilters = cloneDeep(this.filters);
      this.langFilters = cloneDeep(this.filters);
    },
    filtersChange(newFilters) {
      const old = this.filters.length ? cloneDeep(this.filters) : [];
      const names = old.map((el) => el.name);
      const filter_obj = cloneDeep(newFilters[0]);
      const idx = names.indexOf(filter_obj.name);
      if (idx > -1) {
        old[idx].id = filter_obj.id;
      } else {
        old.push(filter_obj);
      }
      this.filters = old.filter((el) => el.id.length);
    },
  },
};
</script>
