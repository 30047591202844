const topicTypeToQueryParam = {
  author: "authors",
  publisher: "publishers",
  psh: "subject_categories",
  language: "lang",
  owner: "owner_institution",
  "work-type": "category",
};


export { topicTypeToQueryParam };
