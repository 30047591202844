import { render, staticRenderFns } from "./ExplicitTopicImportantWorksWidget.vue?vue&type=template&id=63d19d88&"
import script from "./ExplicitTopicImportantWorksWidget.vue?vue&type=script&lang=js&"
export * from "./ExplicitTopicImportantWorksWidget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../locales/common.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fbig-dig-data%2Fshaft%2Fdesign%2Fshaft-ui%2Fsrc%2Fcomponents%2FExplicitTopicImportantWorksWidget.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VLazy } from 'vuetify/lib/components/VLazy';
installComponents(component, {VDataTable,VLazy})
