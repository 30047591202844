var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 py-0",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[(_vm.showOrderingSelection)?_c('v-row',[_c('v-col',{attrs:{"cols":12,"sm":10,"md":6,"lg":4,"pr-2":""}},[_c('v-select',{attrs:{"label":_vm.$t('ordering_criteria'),"items":_vm.orderingOptions,"item-text":"text","item-value":"value"},model:{value:(_vm.orderBy),callback:function ($$v) {_vm.orderBy=$$v},expression:"orderBy"}})],1),_c('v-col',{attrs:{"cols":12,"sm":2,"pl-2":""}},[(_vm.orderBy === 'score_size_ratio')?_c('v-select',{attrs:{"label":_vm.$t('minimum_work_count'),"items":[1, 5, 10, 20, 50]},model:{value:(_vm.minTopicSize),callback:function ($$v) {_vm.minTopicSize=$$v},expression:"minTopicSize"}}):_vm._e()],1)],1):_vm._e(),_c('v-row',{staticStyle:{"height":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":12,"md":_vm.selectedRow ? 4 : 12,"lg":_vm.selectedRow ? 3 : 12}},[_c('v-data-table',{class:_vm.selectedRow ? 'elevation-10' : 'elevation-1',attrs:{"headers":_vm.headers,"items":_vm.rows,"loading":_vm.rowsLoading,"sort-desc":"","items-per-page":_vm.tablePageSize,"page":_vm.tablePage,"footer-props":{
          'items-per-page-text': _vm.$t('table.rows_per_page'),
          'items-per-page-options': _vm.showPageSize ? [5, 10, 15, -1] : [5],
        },"server-items-length":_vm.totalItems,"dense":_vm.compactTable},on:{"update:itemsPerPage":function($event){_vm.tablePageSize=$event},"update:items-per-page":function($event){_vm.tablePageSize=$event},"update:page":function($event){_vm.tablePage=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [(_vm.drillDown)?_c('span',{staticClass:"clickable",class:{ active: _vm.selectedRow && _vm.selectedRow.pk === item.pk },on:{"click":function($event){return _vm.selectRow(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('ExplicitTopicLink',{attrs:{"topic-id":item.pk,"topic-type":_vm.topicType}},[_vm._v(_vm._s(item.name))])]}},{key:"item.score",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatInteger(item.score))+" ")]}},{key:"item.work_count",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatInteger(item.work_count))+" ")]}},{key:"item.score_rel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumberToPlaces(100 * item.score_rel, 2))+" % ")]}},{key:"item.work_count_rel",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumberToPlaces(100 * item.work_count_rel, 2))+" % ")]}},{key:"item.ratio",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumberToPlaces(item.ratio, 2))+" ")]}},{key:"pageText",fn:function(props){return [_vm._v(" "+_vm._s(_vm.$t("table.row_counter", props))+" ")]}}])})],1),(_vm.selectedRow && _vm.drillDown)?_c('v-col',{attrs:{"cols":12,"md":8,"lg":9}},[_c('ExplicitTopicRelatedTopicsWidget',{attrs:{"topic-type":_vm.topicType,"topic-id":_vm.selectedRow.pk}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }