import axios from "axios";
import Cookies from "js-cookie";
import Vue from "vue";

export default {
  state: {
    loginError: null,
    authToken: null,
  },

  getters: {
    loginErrorText(state) {
      if (state.loginError) {
        if (state.loginError?.response?.data?.non_field_errors) {
          return state.loginError.response.data.non_field_errors[0];
        }
        return state.loginError;
      }
      return null;
    },
    canLogout(state, getters) {
      return true;
    },
    showLoginDialog(state) {
      return state.authToken === null;
    },
  },

  actions: {
    async login({ commit, dispatch }, { email, password }) {
      commit("setLoginError", { error: null });

      try {
        let response = await axios.post(
          "/api/rest-auth/login/",
          { email: email, password: password },
          { privileged: true }
        );
        let token = response.data.key;
        await dispatch("setAuthToken", { token: token });
        await Promise.all([dispatch("loadUserData"), dispatch("loadWorksets")]);
      } catch (error) {
        commit("setLoginError", { error: error });
      }
    },
    async logout({ dispatch }) {
      let csrftoken = Cookies.get("csrftoken");
      try {
        await axios.post(
          "/api/rest-auth/logout/",
          {},
          { headers: { "X-CSRFToken": csrftoken } }
        );
      } catch (error) {
        dispatch("showSnackbar", {
          content: "Error logging out:" + error,
          color: "error",
        });
        return;
      }
      await dispatch("cleanUserData");
    },
    async resetPassword({ commit, dispatch }, { email }) {
      let csrftoken = Cookies.get("csrftoken");
      await axios.post(
        "/api/rest-auth/password/reset/",
        { email: email },
        { headers: { "X-CSRFToken": csrftoken }, privileged: true }
      );
    },
    async changePassword({ commit, dispatch }, { password }) {
      let csrftoken = Cookies.get("csrftoken");
      await axios.post(
        "/api/rest-auth/password/change/",
        { new_password1: password, new_password2: password },
        { headers: { "X-CSRFToken": csrftoken } }
      );
    },
    setAuthToken(context, { token }) {
      context.commit("setAuthToken", { token: token });
      if (token) {
        Cookies.set("authtoken", token);
        axios.defaults.headers.common["Authorization"] = "Token " + token;
      } else {
        Cookies.remove("authtoken");
        delete axios.defaults.headers.common["Authorization"];
      }
    },
    async getStoredAuthToken({ dispatch }) {
      let token = Cookies.get("authtoken");
      if (token) {
        console.log("loaded auth token from cookie");
        await dispatch("setAuthToken", { token: token });
        await Promise.all([dispatch("loadUserData"), dispatch("loadWorksets")]);
      }
    },
  },

  mutations: {
    setLoginError(state, { error }) {
      state.loginError = error;
    },
    setAuthToken(state, { token }) {
      Vue.set(state, "authToken", token);
    },
  },
};
