var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-lazy',{attrs:{"min-height":"320","transition":"fade-transition"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rows,"loading":_vm.rowsLoading,"sort-desc":"","items-per-page":_vm.tablePageSize,"page":_vm.tablePage,"footer-props":{
      'items-per-page-text': _vm.$t('table.rows_per_page'),
      'items-per-page-options': _vm.$numRowsOpts,
    },"server-items-length":_vm.totalItems,"dense":""},on:{"update:itemsPerPage":function($event){_vm.tablePageSize=$event},"update:items-per-page":function($event){_vm.tablePageSize=$event},"update:page":function($event){_vm.tablePage=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'work detail', params: { workId: item.pk } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.score",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatInteger(item.score))+" ")]}},{key:"pageText",fn:function(props){return [_vm._v(" "+_vm._s(_vm.$t("table.row_counter", props))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }