var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("works_growth_overview"))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"fa fa-search","label":_vm.$t('search'),"clearable":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rows,"options":_vm.options,"server-items-length":_vm.totalRows,"loading":_vm.loading,"items-per-page":_vm.pageSize,"footer-props":{
      'items-per-page-text': _vm.$t('table.rows_per_page'),
      'items-per-page-options': _vm.$numRowsOpts,
    },"item-key":"pk","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'work detail', params: { workId: item.pk } }}},[_c('ShortenText',{attrs:{"text":item.name,"length":40}})],1)]}},{key:"item.absolute_growth",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:((item.growth_color) + "--text text--darken-2")},[_vm._v(" "+_vm._s(item.absolute_growth)+" ")])]}},{key:"item.relative_growth",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:((item.growth_color) + "--text text--darken-2")},[_vm._v(" "+_vm._s(item.relative_growth)+" ")])]}},{key:"item.lang",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('ExplicitTopicLink',{attrs:{"topic-type":"language","topic-id":item.lang.pk}},[_vm._v(" "+_vm._s(item.lang.name)+" ")]),_c('span',{class:((item.lang.growth_color) + "--text text-caption text--darken-2")},[_vm._v(" "+_vm._s(item.lang.relative_growth)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.lang.relative_growth)+" ("+_vm._s(item.lang.score_yr_b4)+" -> "+_vm._s(item.lang.score_past_yr)+") ")])])]}},{key:"item.authors",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},_vm._l((item.authors),function(author){return _c('span',{key:author.pk},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('ExplicitTopicLink',{attrs:{"topic-type":"author","topic-id":author.pk}},[_vm._v(" "+_vm._s(author.name)+" ")]),_c('span',{class:((author.growth_color) + "--text text-caption text--darken-2")},[_vm._v(" "+_vm._s(author.relative_growth)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(author.relative_growth)+" ("+_vm._s(author.score_yr_b4)+" -> "+_vm._s(author.score_past_yr)+") ")])])],1)}),0)]}},{key:"item.publishers",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},_vm._l((item.publishers),function(publisher){return _c('span',{key:publisher.pk},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('ExplicitTopicLink',{attrs:{"topic-type":"publisher","topic-id":publisher.pk}},[_c('ShortenText',{attrs:{"text":publisher.name,"length":30}})],1),_c('span',{class:((publisher.growth_color) + "--text text-caption text--darken-2")},[_vm._v(" "+_vm._s(publisher.relative_growth)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(publisher.relative_growth)+" ("+_vm._s(publisher.score_yr_b4)+" -> "+_vm._s(publisher.score_past_yr)+") ")])])],1)}),0)]}},{key:"item.subject_categories",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},_vm._l((item.subject_categories),function(subject){return _c('span',{key:subject.pk},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('ExplicitTopicLink',{attrs:{"topic-type":"psh","topic-id":subject.pk}},[_c('ShortenText',{attrs:{"text":subject.name,"length":30}})],1),_c('span',{class:((subject.growth_color) + "--text text-caption text--darken-2")},[_vm._v(" "+_vm._s(subject.relative_growth)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(subject.relative_growth)+" ("+_vm._s(subject.score_yr_b4)+" -> "+_vm._s(subject.score_past_yr)+") ")])])],1)}),0)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }