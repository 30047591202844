var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{attrs:{"clearable":"","placeholder":_vm.$t('search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.score_type == 'candidate_count' ? _vm.headers : _vm.headers.slice(0, 2),"items":_vm.rows,"item-key":"pk","options":_vm.options,"server-items-length":_vm.totalRows,"loading":_vm.loading,"show-select":"","single-select":false,"footer-props":{
      'items-per-page-text': '',
      'items-per-page-options': [],
    },"dense":""},on:{"update:options":function($event){_vm.options=$event},"input":_vm.selectedChange},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('ShortenText',{attrs:{"text":item.name,"length":40}})]}},{key:"item.score",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatInteger(item.score))+" ")]}},{key:"item.relative_growth",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.relative_growth)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.relative_growth)+" ("+_vm._s(item.score_yr_b4)+" -> "+_vm._s(item.score_past_yr)+") ")])])]}},{key:"pageText",fn:function(props){return [_vm._v(" "+_vm._s(_vm.$t("table.row_counter", props))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }