var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"char-card"},[_c('v-card-title',{staticClass:"pb-0 d-flex justify-space-between"},[(_vm.title)?_c('div',[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.titleInfo)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" fa-info-circle ")])]}}],null,false,602322531)},[_c('span',[_vm._v(_vm._s(_vm.titleInfo))])]):_vm._e()],1):_vm._e(),_c('div',[(_vm.clickInfo && _vm.title)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"info"}},'v-icon',attrs,false),on),[_vm._v(" fa-info-circle ")])]}}],null,false,3397665496)},[_c('span',[_vm._v(_vm._s(_vm.clickInfoText))])]):_vm._e(),_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-btn',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-chart-bar")])],1),_c('v-btn',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-table")])],1)],1)],1),(!_vm.title)?_c('div',[(_vm.clickInfo)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"info"}},'v-icon',attrs,false),on),[_vm._v(" fa-info-circle ")])]}}],null,false,2009157368)},[_c('span',[_vm._v(_vm._s(_vm.clickInfoText))])]):_vm._e()],1):_vm._e()]),_c('v-tabs',{attrs:{"color":"accent-4","grow":""}},[_c('v-tabs-items',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab-item',{style:(("height: " + _vm.chartHeight + "px"))},[(!_vm.loading)?_c('BarChart',{attrs:{"rawData":_vm.rawData,"dimension":_vm.dynamicDimension,"metrics":_vm.dynamicMetrics,"yAxis":_vm.yAxis,"chartHeight":_vm.chartHeight,"allowClick":_vm.allowClick},on:{"bar-clicked":_vm.handleClick}}):_c('div',{staticClass:"loader"},[_c('span',{staticClass:"fas fa-cog fa-spin fa-4x"})])],1),_c('v-tab-item',[_c('ChartTable',{attrs:{"columns":[_vm.dynamicDimension ].concat( _vm.dynamicMetrics),"rawData":_vm.rawData,"height":_vm.chartHeight}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }