<i18n src="../locales/common.yaml"></i18n>
<i18n>
en:
  general_stats: General stats
  num_loans: Number of loans
  num_subjects: Number of subjects
  interest_distribution: Interest distribution
  interest_distribution_info: Distribution of subjects based on number of loans
cs:
  general_stats: Celkové statistiky
</i18n>

<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              {{ $t("general_stats") }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col :cols="4">
                  <TopItemsWidget
                    topic-type="psh"
                    order-by="work_count"
                    :rootNode="rootNode"
                  />
                </v-col>
                <v-col :cols="4">
                  <TopItemsWidget
                    topic-type="psh"
                    order-by="score"
                    :rootNode="rootNode"
                  />
                </v-col>
                <v-col cols="4">
                  <BarChartCard
                    :url="`/api/hits/workhit/histogram/WORKSET_UID/psh?root_node=${rootNode}`"
                    :dimension="{ name: $t('num_loans'), value: 'name' }"
                    :metrics="[
                      { name: $t('num_subjects'), value: 'count', type: 'bar' },
                    ]"
                    :title="$t('interest_distribution')"
                    :titleInfo="$t('interest_distribution_info')"
                    :chartHeight="272"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col :cols="6">
                  <TopItemsWidget
                    topic-type="psh"
                    order-by="absolute_growth"
                    :rootNode="rootNode"
                    :includeInfo="true"
                  />
                </v-col>
                <v-col :cols="6">
                  <TopItemsWidget
                    topic-type="psh"
                    order-by="relative_growth"
                    :rootNode="rootNode"
                    :includeInfo="true"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="12" :md="4">
        <SubjectTree
          @filters-change="filtersChange"
          :withLinks="true"
          :parent_uid="rootNodeUid"
        />
      </v-col>
      <v-col v-if="showCandidates" :cols="12" :md="8">
        <v-tabs grow v-model="tab">
          <v-tab>
            {{ $t("pages.works") }}
          </v-tab>
          <v-tab>
            {{ $t("pages.candidates") }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <WorksTable
              :filters="filters"
              :excludeHeaders="['isbn', 'lang.name', 'catalog_date']"
            />
          </v-tab-item>
          <v-tab-item>
            <CandidatesTable
              :filters="filters"
              :excludeHeaders="['isbn', 'langs', 'score']"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col v-else>
        <WorksTable
          :filters="filters"
          :excludeHeaders="['isbn', 'lang.name', 'catalog_date']"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import CandidatesTable from "@/components/CandidatesTable";
import SubjectTree from "@/components/SubjectTree";
import WorksTable from "@/components/WorksTable";
import TopItemsWidget from "@/components/TopItemsWidget";
import BarChartCard from "../components/charts/BarChartCard";

export default {
  name: "SubjectTreePage",
  components: {
    CandidatesTable,
    SubjectTree,
    WorksTable,
    TopItemsWidget,
    BarChartCard,
  },
  props: {
    rootNode: { required: true, type: String },
    rootNodeUid: { required: true, type: String },
    showCandidates: { default: true, type: Boolean },
  },

  data() {
    return {
      tab: null,
      filters: [],
    };
  },

  methods: {
    filtersChange(newFilters) {
      const old = this.filters.length ? cloneDeep(this.filters) : [];
      const names = old.map((el) => el.name);
      const filter_obj = cloneDeep(newFilters[0]);
      const idx = names.indexOf(filter_obj.name);
      if (idx > -1) {
        old[idx].id = filter_obj.id;
      } else {
        old.push(filter_obj);
      }
      this.filters = old.filter((el) => el.id.length);
    },
  },
};
</script>
