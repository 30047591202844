<i18n src="../locales/common.yaml"></i18n>
<i18n>
en:
  growth: Growth
cs:
  growth: Růst
</i18n>

<template>
  <div>
    <v-text-field
      v-model="search"
      clearable
      :placeholder="$t('search')"
    ></v-text-field>

    <v-data-table
      v-model="selected"
      :headers="score_type == 'candidate_count' ? headers : headers.slice(0, 2)"
      :items="rows"
      item-key="pk"
      :options.sync="options"
      :server-items-length="totalRows"
      :loading="loading"
      @input="selectedChange"
      show-select
      :single-select="false"
      :footer-props="{
        'items-per-page-text': '',
        'items-per-page-options': [],
      }"
      class="elevation-2"
      dense
    >
      <template v-slot:item.name="{ item }">
        <ShortenText :text="item.name" :length="40" />
      </template>
      <template v-slot:item.score="{ item }">
        {{ formatInteger(item.score) }}
      </template>
      <template v-slot:item.relative_growth="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ item.relative_growth }}
            </span>
          </template>
          <span>
            {{ item.relative_growth }} ({{ item.score_yr_b4 }} ->
            {{ item.score_past_yr }})
          </span>
        </v-tooltip>
      </template>
      <template v-slot:pageText="props">
        {{ $t("table.row_counter", props) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import debounce from "lodash/debounce";
import { mapActions, mapGetters } from "vuex";
import { formatInteger } from "../libs/numbers";
import ShortenText from "./ShortenText";

export default {
  name: "TopicsFilterTable",
  components: { ShortenText },
  props: {
    header: { required: true, type: String },
    urlArg: { required: true, type: String },
    candidate_count_filters: { default: () => [], type: Array },
    score_type: { default: "candidate_count", type: String },
  },

  data() {
    return {
      selected: [],
      rows: [],
      options: { itemsPerPage: 10 },
      totalRows: 0,
      loading: false,
      justFetchingUrl: null,
      search: "",
      selectedRows: [],
      debounceFetchData: debounce(function () {
        this.fetchData();
      }, 500),
    };
  },

  computed: {
    ...mapGetters({
      worksetUUID: "selectedWorksetUUID",
    }),
    dataUrl() {
      if (!this.worksetUUID) return null;
      return `/api/bookrank/workset/${this.worksetUUID}/et_filters/${this.urlArg}/`;
    },
    headers() {
      return [
        {
          text: this.header,
          value: "name",
          sortable: false,
        },
        {
          text:
            this.score_type == "candidate_count"
              ? this.$i18n.t("columns.score")
              : this.$t("growth"),
          value:
            this.score_type == "candidate_count" ? "score" : "relative_growth",
          align: "right",
          sortable: false,
        },
        {
          text: this.$i18n.t("pages.candidates"),
          value: "candidates_count",
          align: "right",
          sortable: false,
        },
      ];
    },
  },

  watch: {
    ...["search", "options", "candidate_count_filters"].reduce((acc, curr) => {
      acc[curr] = {
        handler(newVal, oldVal) {
          if (!(curr == "options" && newVal.page != oldVal.page)) {
            if (this.options.page != 1) {
              this.options.page = 1;
            }
          }
          if (curr == "candidate_count_filters") {
            newVal.forEach((el) => {
              if (el.name == this.urlArg) {
                this.selected = el.id.map((e) => {
                  return { pk: e };
                });
              }
            });
          }
          this.debounceFetchData();
        },
        deep: ["options", "candidate_count_filters"].includes(curr),
      };
      return acc;
    }, {}),
    dataUrl(newUrl) {
      if (newUrl !== this.justFetchingUrl) this.fetchData();
    },
  },

  methods: {
    ...mapActions({
      showSnackbar: "showSnackbar",
    }),
    formatInteger,
    async fetchData() {
      if (!this.dataUrl) return null;
      this.loading = true;
      this.justFetchingUrl = this.dataUrl;
      try {
        const response = await axios.get(this.dataUrl, {
          params: {
            page: this.options.page,
            page_size: this.options.itemsPerPage,
            q: this.search,
            candidates_filter: this.score_type == "candidate_count" ? 1 : 0,
            show_candidates_count: this.score_type == "candidate_count" ? 1 : 0,
            candidate_count_filters: this.candidate_count_filters,
            score_type: this.score_type,
          },
        });
        if (this.score_type == "growth") {
          this.rows = response.data.results.map((row) => {
            row.relative_growth = this.formatGrowth(row.relative_growth);
            return row;
          });
        } else this.rows = response.data.results;
        this.totalRows = response.data.count;
      } catch (error) {
        this.showSnackbar({
          content: "Error fetching data: " + error,
          color: "error",
        });
      } finally {
        this.loading = false;
      }
    },

    formatGrowth(val) {
      if (val == null) return "-";
      return numeral(val).format(`+0,0%`).replace(/,/g, "\xa0");
    },

    selectedChange(newSelected) {
      this.selectedRows = [
        { name: this.urlArg, id: newSelected.map((el) => el.pk) },
      ];
      this.$emit("filters-change", this.selectedRows);
    },
  },
};
</script>
