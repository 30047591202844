import { render, staticRenderFns } from "./CandidateDetailPage.vue?vue&type=template&id=1f58698a&"
import script from "./CandidateDetailPage.vue?vue&type=script&lang=js&"
export * from "./CandidateDetailPage.vue?vue&type=script&lang=js&"
import style0 from "./CandidateDetailPage.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../locales/availability.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fbig-dig-data%2Fshaft%2Fdesign%2Fshaft-ui%2Fsrc%2Fpages%2FCandidateDetailPage.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "../locales/common.yaml?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fbuilds%2Fbig-dig-data%2Fshaft%2Fdesign%2Fshaft-ui%2Fsrc%2Fpages%2FCandidateDetailPage.vue"
if (typeof block1 === 'function') block1(component)
import block2 from "./CandidateDetailPage.vue?vue&type=custom&index=2&blockType=i18n"
if (typeof block2 === 'function') block2(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VCol,VContainer,VIcon,VRow,VSimpleTable,VTooltip})
