var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('BarChartCard',{attrs:{"url":"/api/bookrank/workset/WORKSET_UID/works/absolute_acquisition_score_summary/","title":_vm.$t('absolute_acquisition_score'),"titleInfo":_vm.$t('absolute_acquisition_score_info'),"dimension":{ name: _vm.$t('year'), value: 'catalog_year' },"metrics":[
          {
            name: _vm.$t('acq_score'),
            value: 'acquisition_score_sum',
            type: 'bar',
          },
          {
            name: _vm.$t('num_works'),
            value: 'work_count',
            type: 'line',
            yAxisIndex: 1,
          } ],"yAxis":[
          {
            name: _vm.$t('acq_score'),
          },
          {
            name: _vm.$t('num_works'),
            splitLine: { lineStyle: { opacity: 0.4 } },
          } ],"formater":"shortenCatalogYear","chartHeight":300,"allowClick":true,"clickInfo":true,"clickInfoText":_vm.$t('click_info')},on:{"bar-clicked":_vm.handleBarClick}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('BarChartCard',{attrs:{"url":"/api/bookrank/workset/WORKSET_UID/works/relative_acquisition_score_summary/","title":_vm.$t('relative_acquisition_score'),"titleInfo":_vm.$t('relative_acquisition_score_info'),"dimension":{ name: _vm.$t('year'), value: 'catalog_year' },"metrics":[
          {
            name: _vm.$t('acq_score'),
            value: 'acquisition_score_sum',
            type: 'bar',
          } ],"formater":"formatRelativeAcqScore","chartHeight":300,"allowClick":true,"clickInfo":true,"clickInfoText":_vm.$t('click_info')},on:{"bar-clicked":_vm.handleBarClick}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('WorksTable',{attrs:{"showCatalogYearFilter":true,"scoreType":"acquisition_score","catalogYear":_vm.catalogYear}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }